.text-chooser {
  right: 0;
  bottom: 0;
  position: absolute;
  font-size: 13px;
  font-family: "Nunito Regular", 'Open Sans', sans-serif;

  img {
    width: 20px;
    height: 12px;
    opacity: 0.4;
    margin-right: 5px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    //align-items: center;
    //flex-wrap: wrap;
    max-height: 115px;
    overflow: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }
    color: rgba(163, 163, 163, 0.4);

    &:hover {
      color: $primary-text;
    }
  }

  &__item {
    min-height: 20px;
    min-width: 250px;
    max-width: 250px;
    text-overflow: ellipsis;
    border: none;
    text-align: left;
    font-size: 13px;
    font-family: "Nunito Regular", 'Open Sans', sans-serif;

    //border: 2px solid rgba(46, 3, 216, 0.467);
    border-radius: 7px;
    margin: 2px 8px;
    padding: 5px;
    white-space: nowrap;
    overflow: clip;
    color: inherit;
    background-color: unset;
    transition: color .2s ease-in-out;
    //box-shadow: 20px 20px 60px #ececec, -20px -20px 60px #f9f9f9;
    &:hover {
      font-family: "Nunito Bold", 'Open Sans', sans-serif;
      //text-decoration: underline;
    }
  }
}
