.card {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  position: relative;
  background: $white;
  z-index: 2;

  &__border {
    position: absolute;
    border: 2px dashed rgba(0,0,0,0);
    transition: border-color .3s ease-in-out;
    z-index: 2;

    &--left {
      top: -5px;
      bottom: -5px;
      left: -5px;
      right: 50%;
      border-top-left-radius: 1.19rem;
      border-bottom-left-radius: 1.19rem;
      border-right: none;
    }

    &--right {
      top: -5px;
      bottom: -5px;
      right: -5px;
      left: 50%;
      border-top-right-radius: 1.19rem;
      border-bottom-right-radius: 1.19rem;
      border-left: none;
    }
  }

  &__inner {
    position: relative;
    border-radius: 1rem;
    border: 2px solid white;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    z-index: 0;
    width: 800px;
    min-height: 300px;
  }

  &__wrapper {
    padding: 30px;

    &--finished {
      filter: blur(1.5px);
    }
  }

  &__finish {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 12;

    button {
      background-color: #2ecc71;
      color: #ffffff;
      //border: 2px solid #fdcb6e;
      border: none;
      padding: 8px 16px;
      font-size: 24px;
      border-radius: 5px;
      font-family: "Nunito Regular", 'Open Sans', sans-serif;
      transition: transform .1s ease-in;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &__id {
    font-family: "Nunito Bold", sans-serif;
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 15px;
    opacity: 0.4;
  }
  &__time {
    font-family: "Nunito Regular", sans-serif;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 13px;
    opacity: 0.3;
  }
  &__text {
    display: flex;
    flex-wrap: wrap;
    font-size: 21px;
    font-family: "Nunito Regular", sans-serif;
    letter-spacing: 1px;
    margin-top: 10px;
  }
  &__row {
    display: flex;
  }
  &__symbol {
    display: flex;
    flex-direction: row;
    transition: color, background-color, text-decoration .1s ease-in-out;
    border-radius: 3px;

    &--typed {
      color: rgb(47, 226, 116);
    }

    &--mistake {
      background-color: #ff3344;
    }

    &--skipped {
      background-color: rgba(255, 51, 68, 0.4);
    }

    &--target {
      text-decoration: underline;
    }
  }
  &__translation {
    font-size: 18px;
    font-family: "Nunito Bold", sans-serif;
    letter-spacing: 1px;
    opacity: 0.5;
    transform: scaleY(0);
    transition: transform .2s ease-in-out;

    &--show {
      transform: scaleY(1);
    }
  }
}
