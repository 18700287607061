.nav {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  z-index: 2;

  &__wrapper {
    display: flex;
    list-style: none;
    padding: 0;
  }

  &__soon {
    position: absolute;
    transform: rotate(45deg);
    background-color: #ff3344;
    color: white;
    padding: 0 7px;
    right: -10px;
    border-radius: 4px;
    font-size: 8px;
    font-family: 'Nunito Semi Bold', sans-serif;
  }

  &__link {
    position: relative;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    padding: 0 15px;
    font-family: 'Nunito Semi Bold', sans-serif;
  }
}
