.word-section {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  position: relative;

  &__inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: end;
  }

  &__restart {
    //position: absolute;
    background: none;
    border: none;
    z-index: 3;
    opacity: 0.4;
    transition: opacity .2s ease-in-out;

    &:hover {
      opacity: 1;
    }

    &--clicked {
      img {
        animation: rotateAnimation 1s cubic-bezier(.88,.37,.29,.95); /* добавить анимацию с cubic-bezier */
      }
    }

    img {
      height: 20px;
      width: 20px;
      transition: transform 1s cubic-bezier(.88,.37,.29,.95); /* убрать анимацию transform из img */
    }

    @keyframes rotateAnimation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;

    div {
      text-align: center;
    }
  }

  &__border {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 0;

    &--left {
      display: flex;
      flex: 1;
      border-right: 3px dashed rgba(0,0,0,0);
      transition: border-color .3s ease-in-out;
    }

    &--right {
      display: flex;
      flex: 1;
      border-left: 3px dashed rgba(0,0,0,0);
      transition: border-color .3s ease-in-out;
    }
  }
}
