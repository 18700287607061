.stats {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: 25px 5% 20px;
  font-size: 13px;
  font-family: "Nunito Regular", 'Open Sans', sans-serif;
  padding: 5px;
  //border: 1px solid rgba(40, 132, 189, 0.467);
  //box-shadow: 20px 20px 60px #ececec, -20px -20px 60px #f9f9f9;
  border-radius: 5px;

  &__wrapper {
    position: relative;
    height: 282px;
    padding-bottom: 20px;
    overflow: hidden;
    transition: all .2s ease-in-out;

    div {
      display: flex;
      align-items: center;
    }
    &--collapsed {
      height: 140px;
    }
  }

  &__header {
    font-size: 14px;
    font-family: 'Nunito Bold', sans-serif;
  }

  button {
    border: none;
    background-color: unset;
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: 0;
    transition: transform .1s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  img {
    height: 20px;
    width: 20px;
    transition: transform .2s ease-in-out;
  }

  .img--rotated {
    transform: rotate(180deg);
  }
}
